import * as React from "react";
import { useState, useEffect } from "react";
import { Container, Modal, Button } from "react-bootstrap";

import "../layouts/gallery.css";

import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

import img0 from "../images/Gallery/Gumussaray/0.jpeg";
import img1 from "../images/Gallery/Gumussaray/1.jpeg";
import img2 from "../images/Gallery/Gumussaray/2.jpeg";
import img3 from "../images/Gallery/Gumussaray/3.jpeg";
import img4 from "../images/Gallery/Gumussaray/4.jpeg";
import img5 from "../images/Gallery/Gumussaray/5.jpeg";
import img6 from "../images/Gallery/Gumussaray/6.jpeg";
import img7 from "../images/Gallery/Gumussaray/7.jpeg";
import img8 from "../images/Gallery/Gumussaray/8.jpeg";
import img9 from "../images/Gallery/Gumussaray/9.jpeg";
import img10 from "../images/Gallery/Gumussaray/10.jpeg";
import img11 from "../images/Gallery/Gumussaray/11.jpeg";
import img12 from "../images/Gallery/Gumussaray/12.jpeg";
import img13 from "../images/Gallery/Gumussaray/13.jpeg";
import img14 from "../images/Gallery/Gumussaray/14.jpeg";
import img15 from "../images/Gallery/Gumussaray/15.jpeg";
import img16 from "../images/Gallery/Gumussaray/16.jpeg";
import img17 from "../images/Gallery/Gumussaray/17.jpeg";
import img18 from "../images/Gallery/Gumussaray/18.jpeg";
import img19 from "../images/Gallery/Gumussaray/19.jpeg";
import img20 from "../images/Gallery/Gumussaray/20.jpeg";
import img21 from "../images/Gallery/Gumussaray/21.jpeg";
import img22 from "../images/Gallery/Gumussaray/22.jpeg";
import img23 from "../images/Gallery/Gumussaray/23.jpeg";

const lang = 'de'

function Gallery() {
  const [ userLanguage, setUserLanguage ] = useState (lang);

  useEffect(() => {
    localStorage.getItem('lang') ? setUserLanguage(localStorage.getItem('lang')) : setUserLanguage(lang);
  }, [])

  let imageData = [
    { id: 0, imgSrc: img0},
    { id: 1, imgSrc: img1},
    { id: 2, imgSrc: img2},
    { id: 3, imgSrc: img3},
    { id: 4, imgSrc: img4},
    { id: 5, imgSrc: img5},
    { id: 6, imgSrc: img6},
    { id: 7, imgSrc: img7},
    { id: 8, imgSrc: img8},
    { id: 9, imgSrc: img9},
    { id: 10, imgSrc: img10},
    { id: 11, imgSrc: img11},
    { id: 12, imgSrc: img12},
    { id: 13, imgSrc: img13},
    { id: 14, imgSrc: img14},
    { id: 15, imgSrc: img15},
    { id: 16, imgSrc: img16},
    { id: 17, imgSrc: img17},
    { id: 18, imgSrc: img18},
    { id: 19, imgSrc: img19},
    { id: 20, imgSrc: img20},
    { id: 21, imgSrc: img21},
    { id: 22, imgSrc: img22},
    { id: 23, imgSrc: img23}
  ];
  const [modalView, setModalView] = useState(false);
  const [tempImgSrc, setTempImgSrc] = useState("");

  const handleClose = () => setModalView(false);

  const getImg = (imgSrc) => {
    setTempImgSrc(imgSrc);
    setModalView(true);
  };

  return (
    <Container
    fluid
    style={{
      marginBottom: "0",
      maxWidth: "100%",
      paddingRight: "0",
      paddingLeft: "0",
    }}>
    {/* <> */}
    <NavBar  setLanguage={setUserLanguage} userLanguage={userLanguage} />

      <Modal
        show={modalView}
        onHide={handleClose}
        fullscreen={true}
        className="modal-style"
      >
        <Modal.Header closeButton closeVariant="white">
          {/* <Modal.Title>Modal heading</Modal.Title> */}
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Header>
        <Modal.Body className="modal-body-style">
          <img src={tempImgSrc} className="modal-image" />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <div className="gallery-heading">
        <h2>Gumussaray</h2>
      </div>
      <div className="gallery">
        {imageData.map((item, index) => {
          return (
            <div className="pics" key={item.index}>
              <img
                src={item.imgSrc}
                onClick={() => getImg(item.imgSrc)}
                width="100%"
                height="100%"
              />
            </div>
          );
        })}
      </div>

      <Footer />
      </Container>
    // </>
  );
}

export default Gallery;
